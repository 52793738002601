<template>
  <v-row dense>
    <v-col cols="12" lg="4" sm="6">
      <DebouncedTextField
        :input="handleInput"
        label="Search Lecture"
        :value="query"
      />
    </v-col>
    <v-col cols="12" lg="4" sm="6">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        item-text="name"
        item-value="uuid"
        :items="courseChoices"
        label="Course"
        solo
        :value="course"
        @input="e => $emit('input-change', 'course', e || '')"
      />
    </v-col>
    <v-col cols="12" lg="2" sm="6">
      <v-dialog
        ref="dialogfromDate"
        v-model="modal"
        persistent
        :return-value.sync="fromDate"
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            class="header-fields"
            clearable
            flat
            hide-details
            label="From Date"
            prepend-inner-icon="mdi-calendar-range"
            readonly
            solo
            :value="fromDate"
            v-on="on"
            @click:clear="$emit('input-change', 'fromDate', '')"
          />
        </template>
        <v-date-picker
          scrollable
          :value="fromDate"
          @input="e => $emit('input-change', 'fromDate', e || '')"
        >
          <v-spacer />
          <v-btn color="primary" text @click="modal = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="$refs.dialogfromDate.save(fromDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
    <v-col cols="12" lg="2" sm="6">
      <v-dialog
        ref="dialogtoDate"
        v-model="modal2"
        persistent
        :return-value.sync="toDate"
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            class="header-fields"
            clearable
            flat
            hide-details
            label="To Date"
            prepend-inner-icon="mdi-calendar-range"
            readonly
            solo
            :value="toDate"
            v-on="on"
            @click:clear="$emit('input-change', 'toDate', '')"
          />
        </template>
        <v-date-picker
          :allowed-dates="fromDate !== '' ? disablePastDates : null"
          scrollable
          :value="toDate"
          @input="e => $emit('input-change', 'toDate', e || '')"
        >
          <v-spacer />
          <v-btn color="primary" text @click="modal2 = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="$refs.dialogtoDate.save(toDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import DebouncedTextField from "@/components/shared/DebouncedTextField.vue"

export default {
  name: "ClassListSearch",

  components: { DebouncedTextField },

  props: {
    query: {
      type: String,
      required: true,
    },
    course: {
      type: String,
      required: true,
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
    fromDate: {
      type: String,
      required: true,
    },
    toDate: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    modal: false,
    modal2: false,
  }),
  computed: {
    ...mapGetters(["courseChoices"]),
  },

  methods: {
    disablePastDates(val) {
      return val >= new Date(this.fromDate).toISOString().substr(0, 10)
    },

    handleInput(e) {
      this.$emit("input-change", "query", e)
    },
  },
}
</script>

<style lang="scss" scoped></style>
