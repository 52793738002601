<template>
  <div>
    <div>
      <v-data-table
        :custom-sort="() => classesDataList.data"
        :headers="headers"
        hide-default-footer
        item-key="uuid"
        :items="classesDataList.data"
        :loading="listLoading"
        multi-sort
        :sort-by="sort.fields"
        :sort-desc="sort.desc"
        @update:options="handleSort"
      >
        <template v-slot:[`header.action`]>
          <span class="subtitle-2 lightCarbon--text">
            {{ totalEntries }}
          </span>
        </template>
        <template v-slot:[`item.course`]="{ item }">
          {{ item.course.join(", ") }}
        </template>
        <template v-slot:[`item.time`]="{ item }">
          {{ moment(item.start_time).format("MMM DD, h:mm A") }}
          -
          {{ moment(item.end_time).format("MMM DD, h:mm A") }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            class="pr-0 pr-md-2"
            color="primary"
            small
            text
            :to="{
              name: 'ViewLectureStudentList',
              params: {
                classId: item.id,
              },
            }"
          >
            VIEW LECTURE
          </v-btn>
        </template>

        <template v-slot:footer>
          <Paginator
            :page="page"
            :total="classesDataList.total"
            @change="handlePagination"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Paginator from "@/components/shared/Paginator.vue"
export default {
  name: "ClassesList",

  components: { Paginator },

  props: {
    classesDataList: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
    handlePagination: {
      type: Function,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    totalEntries: {
      type: String,
      required: true,
    },
    sort: {
      type: Object,
      required: true,
    },
    handleSort: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
